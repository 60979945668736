import React from "react";
import parse from 'html-react-parser';
import Container from "../../atoms/Container/Container";
import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs'
import PageTitle from "../../atoms/PageTitle/PageTitle";
import { handleLink } from "../../../helpers/general";
import { Link } from "gatsby";

const SupportArticle = ({ article }) => {
    return (
        <Container size="medium">
            <Breadcrumbs crumbs={[{link: '/support/', label: 'Support'}, {label: 'Guides'}, {label: article.title}]} />
            <PageTitle title={article.title} />
            <div>
                {parse(article.body)}
            </div>

            <Link className="fancy lightLink" to={handleLink("/support/")} style={{display: 'inline-block', margin: '40px 0 80px'}}>Back to support</Link>
        </Container>
    )
}

export default SupportArticle