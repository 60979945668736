import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/organisms/Layout/Layout';
import SupportArticle from '../components/organisms/SupportArticle/SupportArticle';

const ZDArticle = ({ data }) => {
    const article = (data && data.article) || false;

    return (
        <Layout>
            {article && (
                <>
                    <Helmet title={article.title} />
                    <SupportArticle article={article} />
                </>
            )}
        </Layout>
    );
};

ZDArticle.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default ZDArticle;

export const query = graphql`
  query ZendeskArticleByID($article_id: Float!) {
    article: zenDeskHelpDeskArticles(article_id: { eq: $article_id }) {
        article_id
        section_id
        title
        body
    }
  }
`;
